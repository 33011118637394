.container {
    max-width: 80%;
    margin: auto;
}

.recent .text {
    padding: 30px;
  }
  .recent .category span {
    padding: 4px 15px;
    border-radius: 20px;
    font-weight: 750;
    font-size: 13px;
    display: inline-block;
  }
  .recent .category i {
    font-size: 20px;
    color: #bec7d8;
  }
  .recent p {
    color: #72809d;
  }
  .recent .button {
    border-top: 1px solid #e4e8f3;
    padding: 10px 30px;
  }

  @media screen and (max-width: 970px) {
    .grid3 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
      }
  }