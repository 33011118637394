.about {
    margin-bottom: 50px;
    
    /* margin-top: 50px; */
    /* padding-top: 10px; */
  }
  .about .heading {
    text-align: left;
    width: 100%;
    margin-bottom: 30px;
  }
  .left{
    padding-top: 10px;
  }
  .right img {
    width: 100%;
  }
  .about p {
    font-size: 15px;
    padding-right: 30px;
    margin-bottom: 20px;
    line-height: 30px;
  }
  @media screen and (max-width: 970px){
    .about .container {
      flex-direction: column;
    }
    .about p {
      padding: 0;
    }
    .about .container img {
      margin-top: 50px;
    }
  }