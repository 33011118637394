.location .box {
    position: relative;
    border-radius: 10px;
  }
  .location img {
    border-radius: 10px;
  }
  .location .overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgb(15 28 47 / 50%);
    height: 100%;
    width: 100%;
    color: #fff;
    z-index: 2;
    border-radius: 10px
  }
 

  .location h5 {
    font-size: 18px;
    font-weight: 500;
  }
  .location label {
    color: #fff;
    margin-right: 20px;
    opacity: 0.8;
  }