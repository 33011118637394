.hero {
    background-image: url("../../../../public/images/banner.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 90vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero .container {
    padding-top: 1%;
    padding-bottom: 1px;
}

.hero .container .hero-content {
    text-align: center;
}

.hero h1 {
    color: #fff;
    font-size: 60px;
}

.hero p {
    color: #fff;
    opacity: 0.8;
}

@media screen and (max-width: 768px) {
    .hero .container {
        padding-top: 30%;
        max-width: 100%;
    }
}
