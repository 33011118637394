.awards {
    background-color: #122947;
    color: #fff;
    text-align: center;
  }
  .awards .heading {
    display: flex;
    flex-direction: column-reverse;
  }
  .awards .heading p {
    color: #27ae60;
  }
  .awards .heading h1 {
    color: #fff;
    font-size: 30px;
    font-weight: 400;
  }
  .awards .icon {
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin: auto;
    margin-top: 50px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4% 50%;
    font-size: 32px;
    color: #ffffff;
  }
  .awards h1 {
    font-size: 50px;
  }
  .awards p {
    color: grey;
  }
  @media screen and (max-width: 768px) {
    .grid4 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }}
