.container {
    max-width: 80%;
    margin: auto;
  }
.featured .box {
    box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
    border-radius: 6px;
    text-align: center;
    padding: 30px;
    cursor: pointer;
  }
.featured img {
    width: 65px;
    height: 65px;
    margin: auto;
  }


  @media screen and (max-width: 970px) {
    .grid5 {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 30px;
      }

  }